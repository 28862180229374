<template>
  <div class="home">
    <Carousel />
    <Title title="WELCOME" subtitle="Your flying journey starts now"/>
    <ParallaxImage :imagePath="require('@/assets/images/www/home-parallax.jpg')" />
    <div class="home-about">
      <font-awesome-icon class="arrow-icon" :icon="{ prefix: 'fa', iconName: 'caret-down' }" />
      <h1>PROFESSIONAL FLIGHT TRAINING</h1>
      <p>Flightscope Aviation was started back in 2012 by our Chief Flying Instructor, Rodney Flockhart, while he was a captain at Qantaslink.</p>
      <p>Our aim at Flightscope is to teach good habits while having serious fun!</p>
    </div>
    <Testimonials />
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'
import ParallaxImage from '@/components/ParallaxImage.vue'
import Title from '@/components/Title.vue'
import Testimonials from '@/components/Testimonials.vue'

export default {
  components: {
    Carousel,
    ParallaxImage,
    Title,
    Testimonials
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

.home-about {
  background-color: $secondary;
  padding-bottom: 5vh;
}

.arrow-icon {
  font-size: 5em;
}
</style>
