<template v-slot:controls>
  <splide :options="options">
    <splide-slide>
      <img class="slide-image" src="@/assets/images/aircraft_images/vh-ciz/BD5C3642-cropped.jpg">
      <div class="caption">
        <h1>EXTRA 300 AEROBATIC TIF</h1>
        <h2>Let's get inverted</h2>
      </div>
    </splide-slide>
    <splide-slide>
      <img class="slide-image" src="@/assets/images/www/bne-ciz-ysa-formation-cropped.jpg">
      <div class="caption">
        <h1>FIRST TIMERS - TRIAL INTRODUCTORY FLIGHT</h1>
        <h2>Whether you have always wanted to fly or if you just wanted to try something new</h2>
      </div>
    </splide-slide>
    <splide-slide>
      <img class="slide-image" src="@/assets/images/aircraft_images/24-5350/eurofox5350-2.jpg">
      <div class="caption">
        <h1>RA-AUS - NOW ALLOWED TO FLY IN TOWER HOURS</h1>
        <h2>Get a CASA issued medical and fly when you like</h2>
      </div>
    </splide-slide>
    <splide-slide>
      <img class="slide-image" src="@/assets/images/www/slideshow/home_slideshow_4-1400.jpg">
      <div class="caption">
        <h1>KIERAN BROWN FLYING SCHOLARSHIP</h1>
        <h2>Applications open now</h2>
      </div>
    </splide-slide>
  </splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'

export default {
  data () {
    return {
      options: {
        fixedHeight: '91vh',
        perPage: 1,
        lazyLoad: 'nearby',
        cover: true,
        type: 'loop',
        autoplay: true,
        pagination: false
      }
    }
  },
  components: {
    Splide,
    SplideSlide
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

.caption {
  color: $secondary;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2vh;
  h2 {
    margin-top: 1vh;
  }
}
</style>
