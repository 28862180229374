<template>
  <splide class="testimonials" :options="options">
    <splide-slide>
      <div class="testimonial">
        <q>I had an amazing time today! Thank you Rod for great experience! I'd definitely come back! I recommend anyone who hasn't tried this before to give it a go. I felt very safe learned so much!</q>
        <p class="author">Ariel N.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>I used to fly through another school, but have now joined at Flightscope Aviation. Rod and the rest of the crew are so much more fun and friendly, I already felt like part of the crew, every instructor has been exceptional. I can't wait to see where this journey takes me.</q>
        <p class="author">Shane H.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>Flew the whole time!! Was a fantastic experience! Thanks Chris! Will be back.</q>
        <p class="author">Liam M.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>What a fantastic experience... I was grinning from ear to ear still a week later. This was a birthday gift from my wife -a learn to fly experience - and it was just that. Chris was absolutely fantastic and explained everything so clearly and gave me confidence as I was quite nervous at the beginning of the lesson. From start to finish I was hands on piloting my first ever flight, from taxiing.. take off.. manoeuvres and landing. I cannot thank Chris enough for this and I would love to follow-up with starting my recreational pilot licence... so see you soon guys. Thanks to the team at Flightscope Aviation. Cheers.</q>
        <p class="author">Simon P.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>Great instructors, helpful flight operations team.</q>
        <p class="author">Tony M.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>Never did I think I would have the courage to learn how to fly and here I am just doing it. A massive thanks to the instructors (Rod, Michael &amp; Chris) for making it possible and fun at the same time. Your experience and passion for flying is admirable.</q>
        <p class="author">Barb G.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>Great service, thank you for making the experience so fantastic for my husband.</q>
        <p class="author">Mel M.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>Best experience yet! Thanks Chris for such a memorable and fun flying experience. Right from the get go I felt comfortable and knew that I was in safe hands. I certainly wasn't expecting to be having that much control and opportunity and I absolutely loved it!</q>
        <p class="author">Myf V.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>Yes. My son had a great experience and Chris was fantastic! Thank you so much.</q>
        <p class="author">Deb W.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>This was part of a 30th Birthday gift from my wife and what a Phenomenal experience it was! I am still grinning many hours after my air time. I had so much fun and was allowed as much control as I wanted. Chris is very thorough, knowledgable and was a joy to fly with. I highly recommend Flightscope Aviation to everyone wanting a unique air experience.</q>
        <p class="author">Christopher Z.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>Had a great flight with Richard! Would absolutely recommended it to anyone willing to give it a go! well done Richard on your second day left me with a memory I'll never forget!</q>
        <p class="author">Joshua L.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>I had so much FUN!! The pilots are professional and everyone seemed to have a great sense of humour :)</q>
        <p class="author">Hillary W.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>The most experienced CFI at the airfield in my opinion. I cannot recommend FlightScope enough for their professional, warm and welcoming approach.</q>
        <p class="author">Patrick W.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>It was an amazing experience, I had one hell of great time flying over, I would recommend flightscope to anyone wishing to experience this awesome thrill.</q>
        <p class="author">Jayden K.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>Rod and team are extremely qualified instructors and deliver course matter in a relaxed and informative atmosphere! Couldn't recommend highly enough if you are considering to learn how to fly come here!</q>
        <p class="author">Jack B.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>Rod is a very professional operator and I highly recommend learning to fly at Flightscope. I completed my certificate with Rod and he's always good for a laugh and makes the learning environment enjoyable and relaxed. Anyone thinking of joining the exciting world of aviation should give flightscope a call and train with Rod.</q>
        <p class="author">Jake R.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>Very supportive, down to earth team, and even invited my son and I into the briefing for my daughter. Explanation of all aspects was very clear, and Rob was only too happy to answer any questions asked.</q>
        <p class="author">Caz T.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>An amazing experience. Rob was thorough, patient and was only too happy to answer my questions. I will be back for more lessons!</q>
        <p class="author">Sarah S.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>The crew at Flightscope Aviation were awesome. Both Rod and my instructor Chris were, patient, professional and safety minded. Had an awesome time flying with you guys!</q>
        <p class="author">Paul O.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>Great group of guys and good culture for a flight school. Having a ball.</q>
        <p class="author">Guy D.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>Introductory flight today. New aircraft, great people, outstanding experience!</q>
        <p class="author">Peter O.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>I'd recommend this experience as a great birthday gift.</q>
        <p class="author">Simon D.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>CFI is very friendly and great fun to fly with! Cheers Rod.</q>
        <p class="author">Luke A.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>I had a really great experience! First time flying a plane and the support and knowledge relayed was top notch. Had a blast!!</q>
        <p class="author">Briar E.</p>
      </div>
    </splide-slide>
    <splide-slide>
      <div class="testimonial">
        <q>I highly recommend these guys. Rod was very helpful and professional. Got Me solo again after 16 years. Thank You very much.</q>
        <p class="author">Bud M.</p>
      </div>
    </splide-slide>
  </splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'

export default {
  data () {
    return {
      options: {
        perPage: 1,
        type: 'loop',
        autoplay: true,
        pagination: false
      }
    }
  },
  components: {
    Splide,
    SplideSlide
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_colors.scss';

.testimonials {
  background-color: $accent-background;
  color: $secondary;
}

.testimonial {
  display: inline-block;
  padding: 0 10vw;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

q {
  font-style: italic;
}
</style>
