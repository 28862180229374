<template>
  <div class="parallax" :style="style" />
</template>

<script>
export default {
  props: ['imagePath'],
  computed: {
    style () {
      return `background-image: url(${this.imagePath})`
    }
  }
}
</script>

<style lang="scss" scoped>
.parallax {
  height: 60vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
